<template>
  <div>
    <router-view />
    <back-button></back-button>
  </div>
</template>

<script>

import BackButton from './pages/base/BackButton.vue';
export default {
  name: 'App',
  components: {
    BackButton
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}

.btn-fixed-width {
  width: 80px;
}

.filter-popup {
  display: flex;
  /* 啟用 Flexbox */
  align-items: center;
  /* 垂直居中對齊內容 */
  justify-content: space-between;
  /* 最大化標籤和選擇框之間的空間 */
  /* ...其他現有的CSS屬性... */
}

th.filter:hover {
  background-color: #e0e0e0;
  /* 灰色背景 */
}

th>span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.hidden-column {
  display: none;
}
</style>
